<template>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title"> Add new driver </h4>
        <form action="" method="post" @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">Driver name</label>
                <input type="text" v-model="name" class="form-control" id="name" placeholder="enter driver name here" required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
              <label for="dossier">Dossier</label>
              <input type="text" v-model="dossier" class="form-control" id="dossier" placeholder="enter driver dossier number here" required>
            </div></div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone">Driver Phone</label>
                <input type="text" v-model="phone" class="form-control" id="phone" placeholder="enter driver phone number here" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <button class="btn btn-primary" type="submit">Add driver</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
</template>

<script>

import {confirm} from "../../../../utils/functions";
import ability from "../../../../utils/defineAbility";

export default {
  name: "add-new",
  data() {
    return {
      name: '',
      dossier: '',
      phone: ''
    }
  },
  methods: {

    handleSubmit(){
      const payload = {
        'name': this.name,
        'dossier': this.dossier,
        'phone': this.phone
      }

      console.log('view payload: ' , payload)

      const message = 'A random password will be generated and sent to ' + this.name + ' via SMS';

      confirm(message, () => {
        this.$store.dispatch('addDriver', payload).then(() => {
          this.name = '';
          this.dossier = '';
          this.phone = '';
        });
      })

    }
  },
  created() {
    this.$store.dispatch('setPageTitle','Add Existing Driver');

  }
}
</script>

<style scoped>

</style>